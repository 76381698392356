import React, { useState, useEffect } from "react"
import css from "./SettingsContentModal.module.scss"
import api from "@app/services/api"
import { handleApiError } from "@app/utils"
import TimezoneSetting from "../TimezoneSetting"

type TabName = "Settings" | "Orgs"

type SettingsContentModalProps = {
  onClose: () => void
  clinicId: number
}

const SettingsContentModal: React.FC<SettingsContentModalProps> = ({
  onClose,
  clinicId,
}) => {
  const [activeTab, setActiveTab] = useState<TabName>("Settings")
  const [selectedSettings, setSelectedSettings] = useState<
    Record<string, number>
  >({})
  const [defaultClinicSettings, setDefaultClinicSettings] = useState<
    { setting_type: string; setting_text: string }[]
  >([])
  const [editedTimezone, setEditedTimezone] = useState<string>("")
  const [daylightSavings, setDaylightSavings] = useState<boolean>(false)

  const settingsData = [
    {
      title: "Feature Settings",
      options: [
        { key: "batch_import_export", label: "Batch Import / Export" },
        { key: "calendar_v2", label: "Calendar 2.0" },
        { key: "multicalendar_report", label: "Multi-calendar report" },
        {
          key: "scheduler_edit_group_merged_daily",
          label: "MDV Settings - Edit my Groups protocols/Timezone/Job Times",
        },
        { key: "split_shift", label: "Split shift" },
        {
          key: "always_track_splitshift",
          label: "Always track split shift changes",
        },
        {
          key: "scheduling_engine_rules",
          label: "Scheduling Engine + Rules",
        },
        { key: "casting_jobs", label: "Allow display of empty assignments" },
        { key: "rules_only", label: "Rules Only" },
        { key: "draft_mode", label: "Draft mode" },
        {
          key: "multiple_assignments",
          label: "Allow multiple assignments for one job",
        },
      ],
    },
    {
      title: "TigerConnect Organization Sync Settings",
      options: [
        {
          key: "tigerconnect_one_to_multiple_roles_sync",
          label: "Enable sync from one job to multiple roles",
        },
        {
          key: "scheduling_gap_notifications",
          label: "Notify schedulers of empty TigerConnect Roles",
        },
      ],
    },
  ]

  useEffect(() => {
    const fetchDefaultSettings = async () => {
      try {
        const response = await api.getDefaultsClinicSettings(clinicId)
        setDefaultClinicSettings(response)
      } catch (error) {
        handleApiError(error as PlainObjectType)
      }
    }

    fetchDefaultSettings()
  }, [clinicId])

  useEffect(() => {
    if (defaultClinicSettings.length > 0) {
      const initialSettings = defaultClinicSettings.reduce(
        (acc: Record<string, number>, setting) => {
          acc[setting.setting_type] = parseInt(setting.setting_text, 10)
          return acc
        },
        {}
      )
      const existingTimezone = defaultClinicSettings.find(
        (setting) => setting.setting_type === "timezone"
      )?.setting_text
      const existingDaylightSavings = defaultClinicSettings.find(
        (setting) => setting.setting_type === "daylight_savings"
      )?.setting_text

      if (existingTimezone) setEditedTimezone(existingTimezone)
      if (existingDaylightSavings)
        setDaylightSavings(existingDaylightSavings === "1")

      setSelectedSettings(initialSettings)
    }
  }, [defaultClinicSettings])

  const handleTabClick = (tabName: TabName) => {
    setActiveTab(tabName)
  }

  const handleCheckboxChange = (key: string, isChecked: boolean) => {
    setSelectedSettings((prev) => ({
      ...prev,
      [key]: isChecked ? 1 : 0,
    }))
  }

  const handleSaveSettings = async () => {
    try {
      const settingsPayload = Object.entries(selectedSettings).map(
        ([key, value]) => ({
          clinicid: clinicId,
          setting_type: key,
          setting_text: value,
        })
      )
      const priorityPayload = {
        clinicid: clinicId,
        setting_type: "priority",
        setting_text: "1",
      }
      const additionalSettings = [
        {
          clinicid: clinicId,
          setting_type: "timezone",
          setting_text: editedTimezone,
        },
        {
          clinicid: clinicId,
          setting_type: "daylight_savings",
          setting_text: daylightSavings ? "1" : "0",
        },
      ]

      const finalPayload = [
        ...settingsPayload,
        ...additionalSettings,
        priorityPayload,
      ]

      const updatedSettings = await api.saveDefaultClinicSettings(finalPayload)
      setDefaultClinicSettings(updatedSettings)
      onClose()
    } catch (error) {
      handleApiError(error as PlainObjectType)
    }
  }

  const handleSaveOrgs = () => {
    console.log("Save Orgs logic executed")
  }

  const handleSave = () => {
    if (activeTab === "Settings") {
      handleSaveSettings()
    } else if (activeTab === "Orgs") {
      handleSaveOrgs()
    }
  }

  return (
    <div className={css.tabContent}>
      <div className={css.tabs}>
        <div
          className={`${css.tab} ${
            activeTab === "Settings" ? css.activeTab : ""
          }`}
          onClick={() => handleTabClick("Settings")}
        >
          Settings
        </div>
        <div
          className={`${css.tab} ${css.disabledTab} ${
            activeTab === "Orgs" ? css.activeTab : css.disabledTab
          }`}
          onClick={() => handleTabClick("Settings")}
        >
          Orgs
        </div>
      </div>

      <div className={css.timezoneContent}>
        <h3>Timezone</h3>
        <div className={css.timezoneSetting}>
          <TimezoneSetting
            timezone={editedTimezone}
            onChange={setEditedTimezone}
          />
          <label className={css.optionLabel}>
            <input
              type="checkbox"
              className={css.checkbox}
              checked={daylightSavings}
              onChange={(e) => setDaylightSavings(e.target.checked)}
            />
            DST
          </label>
        </div>
      </div>
      <div className={css.content}>
        {activeTab === "Settings" && (
          <div className={css.settingsContent}>
            <div className={css.settingsContent}>
              {settingsData.map((section, sectionIndex) => {
                if (section.title === "Feature Settings") {
                  const firstColumnOptions = section.options.filter(
                    (option) =>
                      ![
                        "calendar_v2",
                        "scheduler_edit_group_merged_daily",
                        "scheduling_engine_rules",
                        "rules_only",
                      ].includes(option.key)
                  )
                  const secondColumnOptions = section.options.filter((option) =>
                    [
                      "calendar_v2",
                      "scheduler_edit_group_merged_daily",
                      "scheduling_engine_rules",
                      "rules_only",
                    ].includes(option.key)
                  )
                  return (
                    <div key={sectionIndex} className={css.section}>
                      <h3>{section.title}</h3>
                      <div className={css.optionsGridTwoColumns}>
                        <div className={css.column}>
                          {firstColumnOptions.map((option) => (
                            <label key={option.key} className={css.optionLabel}>
                              <input
                                type="checkbox"
                                className={css.checkbox}
                                checked={selectedSettings[option.key] === 1}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    option.key,
                                    e.target.checked
                                  )
                                }
                              />{" "}
                              {option.label}
                            </label>
                          ))}
                        </div>
                        <div className={css.column}>
                          {secondColumnOptions.map((option, index) => (
                            <React.Fragment key={option.key}>
                              {index === 2 && (
                                <p className={css.subtitle}>
                                  <em>DON'T CHECK BOTH OF THE FOLLOWING:</em>
                                </p>
                              )}
                              <label className={css.optionLabel}>
                                <input
                                  type="checkbox"
                                  className={css.checkbox}
                                  checked={selectedSettings[option.key] === 1}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      option.key,
                                      e.target.checked
                                    )
                                  }
                                />{" "}
                                {option.label}
                              </label>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  )
                }
                return (
                  <div key={sectionIndex} className={css.section}>
                    <h3>{section.title}</h3>
                    <div className={css.optionsColumn}>
                      {section.options.map((option) => (
                        <label key={option.key} className={css.optionLabel}>
                          <input
                            type="checkbox"
                            className={css.checkbox}
                            checked={selectedSettings[option.key] === 1}
                            onChange={(e) =>
                              handleCheckboxChange(option.key, e.target.checked)
                            }
                          />{" "}
                          {option.label}
                        </label>
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        {activeTab === "Orgs" && (
          <div className={css.tabContentTitle}>
            <h3>Orgs Content</h3>
            <p>Here goes the content for the Orgs tab.</p>
          </div>
        )}
      </div>
      <div className={css.buttonContainer}>
        <button className={css.cancelSettings} onClick={onClose}>
          Cancel
        </button>
        <button className={`${css.saveSettings}`} onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  )
}

export default SettingsContentModal
